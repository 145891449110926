/*-----------------------
 * Banner Style One
------------------------*/

.height-300 {
    min-height: 300px;
}

.height-640 {
    min-height: 640px;
    width: 100%;
    padding: 140px 0;
    @media #{$sm-layout} {
        min-height: auto;
        width: 100%;
        padding: 250px 0;
    }
}

.height-850 {
    min-height: 850px;
    width: 100%;
    padding: 240px 0 190px;
    @media #{$sm-layout} {
        min-height: auto;
        width: 100%;
        padding: 250px 0;
    }
}

.height-940 {
    min-height: 875px;
    width: 100%;
    @media #{$sm-layout} {
        min-height: auto;
        width: 100%;
        padding: 250px 0;
    }
}

.slider-area {
    padding: 0 0 100px;
    // overflow: hidden;
    height: 880px;
}

.carousel-area {
    overflow: hidden;
}

.banner-slider {
    margin-bottom: -68px;
    @media #{$custom-laptop-device} {
        margin-bottom: -67px;
    }

    ul.slick-dots {
        padding: 0 0 100px 0;
        margin: 0;
        bottom: -50px!important;
        li.slick-active {
            margin: 0 1px!important;
        }
        @media #{$sm-layout} {
            bottom: -50px!important;
        }
    }
    .slick-slider {
        max-width: 1600px;
        margin: auto;
        button.slick-arrow::before {
            color: var(--color-primary);
            font-size: 32px;
        }
        button.slick-prev {
            left: 12px;
            z-index: 2;
            &:before {
                content: '\002770';
            }
        }
        button.slick-next {
            right: 12px;
            z-index: 2;
            &:before {
                content: '\002771';
            }
        }
    }    
    .scroll-down-btn {
        position: relative;
        z-index: 3;
        bottom: 38px;
        cursor: pointer;
    }
}

.banner-one-mobile {
    // background: url(../../images/bg/cleantechai_v2.png)!important;
    // background-position: 0% 40%!important;
    // background-repeat: no-repeat!important;
    // background-size: cover!important;
    // transform: rotate(30deg);
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        top: -50%;
        left: -50%;
        z-index: -1;
        background: url(../../images/bg/cleantechai_v3.png) 0% 55% no-repeat;
        background-size: 170%!important;
        transform: rotate(-8deg);
      }
}

.banner-our-solutions {
    background: url(../../images/bg/cleantechai_v3.png)!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.lottie-animation-banner-right {
    margin-top: -20px!important;
}

.banner-two-mobile {
    background: url(../../images/bg/Default_computer_code_code_travelling_between_devices_futurist_0_v4.png)!important;
    background-position: 80% 40%!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.banner-two {
    background: url(../../images/bg/Default_computer_code_code_travelling_between_devices_futurist_0_v4.png)!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.banner-three-mobile {
    background: url(../../images/bg/skillsboost_banner_v3.webp)!important;
    background-position: center bottom!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.banner-three {
    background: url(../../images/bg/skillsboost_banner_v3.webp)!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}

.applied-ai-solutions-banner {
    background-position: 40% 40%!important;
    .banner-left-content {
        min-height: 570px;
        margin-top: 0!important;
    }
}

.banner-style-1 {
    position: relative;
    // background: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.5)),url(../../../../public/images/banner/banner-01/M2M-Landing-5.png);
    background: url(../../../../public/images/banner/banner-01/M2M-Landing-5.webp);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    @media #{$lg-layout} {
        height: auto;
        min-height: auto;
        padding: 100px 0;
        padding-top: 150px;
    }
    @media #{$md-layout} {
        height: auto;
        min-height: auto;
        padding: 100px 0;
        padding-top: 150px;
    }
    @media #{$sm-layout} {
        height: auto;
        min-height: auto;
        padding: 124px 0 100px 0;
        background-position: center bottom;
        // padding-top: 150px;
    }
    // &::before {
    //     background-color: rgba(255,255,255,0.8);
    // }

    .banner-right-content {
        position: relative;
        margin-top: 138px;
        padding: 0;
        @media #{$smlg-device} {
            margin-top: 30px;
        }
        .edu-card {
            // min-width: 269px;
            @media #{$sm-layout} {
                min-width: inherit;
            }
            .inner {
                .content {
                    .card-bottom {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        & > .row {
            padding-left: 0;

        }
        .banner-right-content-left-section {
            padding: 0 12px;
        }
    }

    .banner-left-content {
        margin-top: 120px;
        display: flex;
        align-items: center;
        @media #{$smlg-device} {
            margin-top: 0;
        }
    }

    .slider-banner-left-content {
        min-height: 570px;
    }

    .inner {
        .content {
            .pre-title {
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 16px;
                line-height: 26px;
                color: var(--color-primary);
                display: block;
                margin-bottom: 10px;
                background-color: #fff;
                border-radius: 5px;
            }
            .title {
                font-weight: 800;
                font-size: 72px;
                line-height: 1.25;
                margin-bottom: 30px;
                
                @media #{$lg-layout} {
                    font-size: 52px;
                    margin-bottom: 20px;
                }
                
                @media #{$md-layout} {
                    font-size: 45px;
                }

                @media #{$sm-layout} {
                    font-size: 38px;
                }
            }

            .description {
                margin-bottom: 60px;
                padding-left: 20px;
                padding-right: 10px;
                position: relative;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                background-color: #fff;
                border-radius: 5px;

                @media #{$lg-layout} {
                    margin-bottom: 30px;
                }
                @media #{$md-layout} {
                    margin-bottom: 30px;
                }
                @media #{$sm-layout} {
                    margin: 0 auto 30px;
                    font-size: 18px;
                    line-height: 28px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    width: 2px;
                    height: 73%;
                    background: var(--color-primary);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .work-shop {
        // min-width: 193px;
        // max-width: 211px;
        background: var(--color-white);
        padding: 12px;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin: 12px 0 0 auto;
        @media #{$lg-layout} {
            min-width: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @media #{$md-layout} {
            min-width: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @media #{$sm-layout} {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            margin-right: 0;
            margin-left: 0;
            .inner {
                display: flex;
                .content {
                    // display: flex;
                    .content-description {
                        margin-left: 10px;
                        .title {
                            margin: -6px 0 0!important;
                        }
                    }
                    .edu-button {
                        width: 100%;
                    }
                }
            }
        }
        .inner {
            display: flex;
            flex-direction: row;
            .thumbnail {
                img {
                    width: 90px;
                    height: 70px;
                    object-fit: cover;
                }
            }

            .content {
                padding-left: 8px;
                .title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    margin: -6px 0 0;
                }
            }
        }
        .time {
            font-weight: 600;
            font-size: 13px;
            line-height: 22px;
            display: inline-block;
            margin-bottom: 8px;
        }
        .edu-button {
            gap: 12px;
        }
    }
    
    .shape-round {
        position: absolute;
        left: -312px;
        top: 130px;
        z-index: -1;
    }

    .scroll-down-btn {
        position: absolute;
        top: 100%;
        left: calc(50% - 30px);
        transform: translateY(-50%);
        z-index: 1;
    }

    .shape-dot-wrapper {
        .shape {
            position: absolute;
            z-index: -1;

            &.shape-1 {
                top: 97px;
                left: -189px;
            }

            &.shape-2 {
                top: 53px;
                left: 580px;
            }

            &.shape-3 {
                top: 95px;
                right: -160px;
            }

            &.shape-4 {
                bottom: 10px;
                right: -173px;
            }

            &.shape-5 {
                bottom: -23px;
                left: 654px;
            }

            &.shape-6 {
                bottom: -37px;
                left: -255px;
            }
        }
    }

    .eduvibe-hero-one-video {
        .thumbnail {
            margin: 30px -149px 0 -23px;
            @media #{$custom-laptop-device} {
                margin: 0 -70px 0 -30px;
            }
            @media #{$smlg-device} {
                // margin: 0 -30px 0 -30px;
                margin: 0;
            }
            @media #{$large-mobile} {
                // margin: -30px 0 0 -30px;
            }
            img {
                max-width: fit-content;
            }
        }
    }
}

.banner-style-students-spotlight {
    background-image: url(../../images/bg/student-spotlight_v3.webp);
    background-position: center;
}

.data-analytics-banner {
    background-image: url(../../images/bg/data-analytics.webp);
    background-position: center;
}

// .student-spotlight-banner {
//     background-image: url(../../images/bg/student-spotlight.webp);
//     background-position: center;
// }

.coding-banner {
    background-image: url(../../images/bg/coding-banner_v1.webp);
    background-position: center;
}

.design-banner {
    background-image: url(../../images/bg/design-banner_v1.webp);
    background-position: center;
}

.machine-learning-banner {
    background-image: url(../../images/bg/machine-learning.webp);
    background-position: center;
}

.deep-learning-banner {
    background-image: url(../../images/bg/deep-learning-banner_v1.webp);
    background-position: center;
}

.data-talent-banner {
    background-image: url(../../images/bg/wilplus-banner_v3.webp);
    background-position: center;
}

.join-our-growing-team-banner {
    background-image: url(../../images/bg/join-our-growing-team.webp);
    background-position: center;
}

/*-------------------------
    Banner Style Two  
--------------------------*/
.banner-style-2 {
    background-image: url(../../images/bg/home-two-banner.jpg);
    position: relative;
    z-index: 1;
    min-height: 930px;

    @media #{$smlg-device} {
        min-height: 800px;
    }

    @media #{$lg-layout} {
        padding: 0 30px;
    }

    @media #{$md-layout} {
        padding: 150px 30px;
        height: auto;
        padding-bottom: 0;
    }

    @media #{$sm-layout} {
        padding: 150px 0px;
        height: auto;
        padding-bottom: 0;
    }
    
    .inner {
        position: relative;
        margin-top: 100px;
        @media #{$smlg-device} {
            margin-top: 0px;
        }
        .content {
            .pre-title {
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 16px;
                line-height: 26px;
                color: var(--color-primary);
                margin-bottom: 10px;
                display: block;
                padding-left: 60px;
                position: relative;

                &::before {
                    left: 0;
                    top: 13px;
                    width: 50px;
                    height: 2px;
                    background: var(--color-primary);
                    content: "";
                    position: absolute;
                }
            }

            .title {
                font-weight: 800;
                font-size: 68px;
                line-height: 1.24;
                margin-bottom: 30px;

                @media #{$lg-layout} {
                    font-size: 45px;
                }
                @media #{$md-layout} {
                    font-size: 40px;
                    margin-bottom: 15px;
                }
                @media #{$sm-layout} {
                    font-size: 38px;
                    margin-bottom: 15px;
                }
                @media #{$large-mobile} {
                    font-size: 32px;
                }
            }
            .description {
                margin-bottom: 60px;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
                @media #{$md-layout} {
                    margin-bottom: 40px;
                }
                @media #{$sm-layout} {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 25px;
                }
            }
            .arrow-sign {
                position: absolute;
                right: -30px;
                bottom: -50px;
                img {
                    max-width: 70%;
                }
                @media #{$custom-lg-device-three} {
                    right: -50px;
                    bottom: -90px;
                    img {
                        max-width: 100%;
                    }
                }

                @media #{$laptop-device} {
                    right: -50px;
                    bottom: -30px;
                    img {
                        max-width: 50%;
                    }
                }

                @media #{$lg-layout} {
                    right: -110px;
                    bottom: -12px;
                    img {
                        max-width: 40%;
                    }
                }
            }
        }
    }

    .banner-thumbnail {
        position: absolute;
        bottom: 0;
        right: 111px;
        z-index: 1;

        @media #{$laptop-device} {
            right: 31px;
        }
        img {
            max-width: calc(100% - 60px );
        }
        @media #{$custom-laptop-device} {
            right: 0;
            img.girl-thumb {
                max-width: 90%;
            }
        }

        @media #{$smlg-device} {
            right: -138px;
            img.girl-thumb {
                max-width: 80%;
            }
        }

        @media #{$md-layout} {
            position: static;
            text-align: right;
            margin-top: -110px;
            img.girl-thumb {
                max-width: 70%;
            }
        }

        @media #{$sm-layout} {
            position: static;
            img.girl-thumb {
                max-width: 100%;
            }
        }
    }

    .banner-bg {
        position: absolute;
        bottom: 3px;
        right: 67px;
        z-index: 0;

        @media #{$custom-laptop-device} {
            right: 0;
        }
        img.girl-bg {
            max-width: calc(90% - 10px);
            @media #{$custom-laptop-device} {
                max-width: 90%;
            }
        }

        @media #{$laptop-device} {
            right: -40px;
        }

        @media #{$smlg-device} {
            right: -218px;
            img.girl-bg {
                max-width: 80%;
            }
        }
    }

    .shape-image {
        position: absolute;
        z-index: -1;

        &.shape-image-1 {
            top: 231px;
            left: 50px;
            @media #{$laptop-device} {
                top: 170px;
                left: -10px;
            }
        }

        &.shape-image-2 {
            top: 240px;
            right: 43%;
        }

        &.shape-image-3 {
            top: 250px;
            right: 90px;
        }
    }
}

/*-------------------------
    Banner Style Three  
--------------------------*/

.banner-style-3 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-image: url(../../images/bg/home-three-banner.jpg);
    @media #{$small-tablet} {
        padding: 160px 0 100px;
        .height-940 {
            height: inherit;
            min-height: inherit;
        }
    }
    @media #{$sm-layout} {
        padding: 140px 0 70px;
        .height-940 {
            padding: 0;
        }
    }
    .content {
        margin-top: 90px;
        @media #{$small-tablet} {
            margin-top: 0px;
        }
        .pre-title {
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 16px;
            line-height: 26px;
            color: var(--color-primary);
            display: block;
            margin-bottom: 5px;
        }
        .title {
            font-weight: 800;
            font-size: 68px;
            line-height: 1.25;
            margin-bottom: 25px;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 40px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }

        .description {
            margin-bottom: 60px;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            @media #{$md-layout} {
                margin-bottom: 30px;
            }
            @media #{$sm-layout} {
                margin-bottom: 30px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .banner-image {
        .banner-main-image {
            margin-right: -45px;
            margin-bottom: -260px;
            text-align: right;
            @media #{$custom-laptop-device} {
                margin-right: 70px;
            }
            @media #{$lg-layout} {
                margin-right: 50px;
            }
            @media #{$small-tablet} {
                margin-right: 0;
                text-align: left;
                margin-bottom: 0;
            }
        }
        .img-02 {
            position: absolute;
            right: -232px;
            bottom: 125px;
            @media #{$custom-lg-device-six} {
                right: -152px;
                bottom: 190px;
                max-width: 240px;
            }
            @media #{$custom-laptop-device} {
                right: -88px;
            }
            @media #{$lg-layout} {
                right: -88px;
                max-width: 240px;
            }
            @media #{$small-tablet} {
                display: none;
            }
        }
        .img-03 {
            position: absolute;
            right: 0;
            bottom: 435px;
            @media #{$custom-laptop-device} {
                right: 140px;
            }
            @media #{$lg-layout} {
                right: 90px;
                bottom: 345px;
            }
            @media #{$small-tablet} {
                display: none;
            }
        }
    }

    .shape-image {
        position: absolute;
        z-index: -1;

        &.shape-image-1 {
            left: -114px;
            top: 30px;
        }

        &.shape-image-2 {
            right: 45%;
            top: 105px;
        }

        &.shape-image-3 {
            right: -260px;
            top: 20px;
        }

        &.shape-image-4 {
            right: -241px;
            bottom: -50px;
        }

        &.shape-image-5 {
            left: -180px;
            bottom: -100px;
        }
    }
}

/*-------------------------
    Banner Style Four  
--------------------------*/

.banner-style-4 {
    background-image: url(../../images/bg/home-four-banner.jpg);
    position: relative;
    z-index: 1;
    overflow: hidden;
    .home-four-banner-wrapper {
        min-height: 870px;
        @media #{$smlg-device} {
            min-height: auto;
            width: 100%;
            width: 100%;
            padding: 120px 0 100px;
        }
        @media #{$small-tablet} {
            padding: 70px 0 150px;
        }
    }
    .banner-image {
        position: relative;
        padding: 35px;
        left: 115px;
        @media #{$lg-layout} {
            padding: 25px;
            left: 10px;
            transform: scale(0.9);
        }
        @media #{$md-layout} {
            left: 0;
            width: 74%;
            margin: 0 auto;
        }
        @media #{$sm-layout} {
            left: 0;
            margin: 0 auto;
            padding: 30px;
        }
        @media #{$large-mobile} {
            padding: 20px;
        }
        .thumbnail {
            img {
                width: 500px;
                max-height: 500px;
                border-radius: 100%;
                object-fit: cover;
            }
        }
        .round-images {
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
        }
        .learner-badge {
            background: #FFFFFF;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            padding: 10px;
            width: 160px;
            height: 160px;
            border-radius: 100%;
            position: absolute;
            left: -80px;
            top: 25%;
            @media #{$large-mobile} {
                display: none;
            }
            .badge-inner {
                background: #FFA41B;
                border-radius: 100%;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                span {
                    display: block;
                    color: #FFFFFF;
                    font-weight: 600;

                    &.viewer {
                        font-weight: 800;
                        font-size: 32px;
                        line-height: 44px;
                    }
                }
            }
        }
    }

    .content {
        .pre-title {
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 16px;
            line-height: 26px;
            color: var(--color-primary);
            display: block;
            margin-bottom: 5px;
        }
        .title {
            font-weight: 800;
            font-size: 72px;
            line-height: 1.25;
            margin-bottom: 25px;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 40px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }

        .description {
            margin-bottom: 60px;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            @media #{$md-layout} {
                margin-bottom: 30px;
            }
            @media #{$sm-layout} {
                margin-bottom: 30px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .shape-image {
        position: absolute;
        z-index: -1;

        &.shape-image-1 {
            left: -110px;
            top: -50px;
        }

        &.shape-image-2 {
            right: 48%;
            top: -32px;
        }

        &.shape-image-3 {
            right: -210px;
            top: 10px;
        }

        &.shape-image-4 {
            right: 360px;
            bottom: -90px;
        }

        &.shape-image-5 {
            left: 463px;
            bottom: 270px;
        }

        &.shape-image-6 {
            left: 150px;
            bottom: -100px;
        }
        &.shape-image-left {
            left: 0;
            bottom: 8px;
        }
        &.shape-image-right {
            right: 0;
            bottom: 8px;
        }
    }

    .social-text-share {
        @extend %liststyle;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -175px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @media #{$laptop-device} {
            left: -105px;
        }
        @media #{$custom-laptop-device} {
            left: -70px;
        }
        @media #{$smlg-device} {
            position: absolute;
            top: inherit;
            transform: none;
            left: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            bottom: -40px;
            margin: 0 -20px;
        }
        @media #{$small-tablet} {
            bottom: -65px;
        }

        li {
            margin: 0;
            a {
                display: inline-block;
                transform: rotate(-90deg);
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                margin: 20px 0;
                position: relative;
                @media #{$smlg-device} {
                    transform: none;
                    margin: 0px 20px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    background: var(--color-primary);
                    bottom: 0;
                    width: 0;
                    opacity: 0;
                    left: 0;
                    transition: 0.4s;
                    height: 2px;
                }
                &:hover {
                    &::before {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
}


/*-------------------------
    Banner Style five  
--------------------------*/

.banner-style-5 {
    position: relative;
    z-index: 1;
    background-image: url(../../images/bg/home-five-banner.jpg);
    .wrapper {
        height: 860px;
        @media #{$lg-layout} {
            height: auto;
            padding-top: 120px;
        }

        @media #{$md-layout} {
            height: auto;
            padding-top: 120px;
        }

        @media #{$sm-layout} {
            height: auto;
            padding-top: 120px;
        }
    }

    .content {
        .title {
            font-weight: 800;
            font-size: 72px;
            line-height: 1.28;
            margin-bottom: 30px;
            
            @media #{$laptop-device} {
                font-size: 57px;
                margin-bottom: 20px;
            }

            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 40px;
                margin-bottom: 20px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
                margin-bottom: 20px;
            }
        }
        .description {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 60px;
            
            @media #{$lg-layout} {
                margin-bottom: 30px;
            }
            
            @media #{$md-layout} {
                margin-bottom: 30px;
            }

            @media #{$sm-layout} {
                margin-bottom: 30px;
            }
        }
        .read-more-btn {
            display: flex;
            align-items: center;
            @media #{$large-mobile} {
                display: block;
            }
        }

        .video-btn-wrapper {
            @media #{$large-mobile} {
                margin-left: 0;
                margin-top: 20px;
            }
        }
        .video-play-btn {
            width: 60px;
            height: 60px;
            background: rgba(251, 124, 86, 0.15);
            margin: inherit;
            margin-left: 30px;
            @media #{$large-mobile} {
                margin-left: 0;
                margin-top: 0;
            }
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
            .play-icon {
                &::before {
                    border-top: 9px solid transparent;
                    border-bottom: 9px solid transparent;
                    border-left: 15px solid var(--color-secondary);
                }
            }
        }
        .video-btn-wrapper {
            display: flex;
            align-items: center;
            .video-text {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                margin-left: 20px;
                display: inline-block;
                color: var(--color-heading);
            }
        }
    }
    .banner-image {
        position: absolute;
        bottom: 0;
        right: 13%;
        @media #{$custom-lg-device} {
            right: 4%;
        }
        @media #{$lg-layout} {
            position: relative;
            bottom: 0;
            right: 0;
        }
        @media #{$md-layout} {
            position: relative;
            bottom: 0;
            right: 0;
            text-align: center;
        }
        @media #{$sm-layout} {
            position: relative;
            bottom: 0;
            right: 0;
            text-align: center;
        }
        img {
            border-top-right-radius: 500px;
            border-top-left-radius: 500px;

            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }

        .card-info {
            position: absolute;
            left: -10%;
            top: 19%;
            @media #{$md-layout} {
                left: 0;
            }
            @media #{$sm-layout} {
                left: 10px;
                top: auto;
                bottom: 10px;
            }
            .inner {
                background: #FFFFFF;
                box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
                border-radius: 5px;
                padding: 20px 30px;

                .name {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--color-heading);

                    span {
                        font-size: 14px;
                        color: var(--color-body);
                        font-weight: 400;
                    }
                }

                .rating-wrapper {
                    span {
                        display: inline-block;
                        margin-left: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }

        }
    }

    .shape-image {
        position: absolute;
        z-index: -1;

        &.shape-image-1 {
            bottom: 235px;
            left: 168px;
            animation: eduvibe-vsm-y-move 3.5s alternate infinite linear;
            @media #{$custom-lg-device} {
                left: 28px;
            }
            @media #{$custom-laptop-device} {
                display: none;
            }
        }

        &.shape-image-2 {
            top: 183px;
            left: 73px;
            @media #{$custom-laptop-device} {
                top: 160px;
                left: -40px;
            }
        }

        &.shape-image-3 {
            top: 180px;
            left: 52%;
        }

        &.shape-image-4 {
            left: 42%;
            bottom: 370px;
            @media #{$custom-laptop-device} {
                bottom: 200px;
            }
        }

        &.shape-image-5 {
            bottom: 116px;
            left: 49%;
        }

        &.shape-image-6 {
            left: 50px;
            bottom: 90px;
        }
    }
}

/*-------------------------
    Banner Style Six  
--------------------------*/

.banner-style-6 {
    position: relative;
    z-index: 2;

    .scroll-down-btn {
        position: absolute;
        bottom: 30px;
        left: calc(50% - 30px);
        z-index: 1;
        @media #{$large-mobile} {
            bottom: 15px;
        }
        @media #{$custom-lg-device-three} {
            bottom: 20px;
        }
        @media #{$custom-lg-device-four} {
            bottom: 0px;
        }
        @media #{$custom-lg-device-five} {
            bottom: -20px;
        }
    }

    .content {
        position: relative;
        z-index: 1;
        .pre-title {
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 16px;
            line-height: 26px;
            color: var(--color-primary);
            display: block;
            margin-bottom: 10px;
        }

        .title {
            font-weight: 800;
            font-size: 68px;
            line-height: 1.24;
            margin-bottom: 60px;
            @media #{$lg-layout} {
                font-size: 50px;
                margin-bottom: 30px;
            }
            @media #{$md-layout} {
                font-size: 50px;
                margin-bottom: 30px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
                margin-bottom: 30px;
            }
            @media #{$large-mobile} {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }
    }

    .shape-image {
        position: absolute;
        z-index: -1;

        &.shape-image-1 {
            top: 180px;
            left: 32%;
        }

        &.shape-image-2 {
            top: 253px;
            right: 182px;
        }

        &.shape-image-3 {
            bottom: 100px;
            right: 35%;
        }

        &.shape-image-4 {
            bottom: 195px;
            left: 117px;
        }

        &.shape-image-5 {
            bottom: 360px;
            right: 192px;
        }

    }

    .gallery-image {
        position: absolute;
        z-index: -1;

        &.gallery-image-1 {
            top: 180px;
            left: 175px;
            z-index: 1;
        }

        &.gallery-image-2 {
            top: 353px;
            left: 117px;
            z-index: -1;
        }

        &.gallery-image-3 {
            bottom: 100px;
            left: 340px;
        }

        &.gallery-image-4 {
            bottom: 200px;
            right: 281px;
        }

        &.gallery-image-5 {
            bottom: 91px;
            right: 6%;
            z-index: -1;
        }

        &.gallery-image-6 {
            top: 201px;
            right: 281px;
        }

        @media #{$custom-lg-device-two} { 
            img {
                max-width: 90%;
            }
            
            &.gallery-image-1 {
                left: 20px;
            }
    
            &.gallery-image-2 {
                top: 350px;
                left: 40px;
            }
    
            &.gallery-image-3 {
                left: 120px;
                bottom: 150px;
            }
    
            &.gallery-image-4 {
                bottom: 290px;
                right: 0px;
            }
    
            &.gallery-image-5 {
                bottom: 170px;
                right: 120px;
            }
    
            &.gallery-image-6 {
                right: 0;
            }
        }

        @media #{$custom-laptop-device} {
            &.gallery-image-1 {
                left: 0;
            }
    
            &.gallery-image-2 {
                top: 305px;
                left: 70px;
            }
    
            &.gallery-image-3 {
                left: 200px;
                bottom: 150px;
            }
    
            &.gallery-image-4 {
                right: 130px;
            }
    
            &.gallery-image-5 {
                bottom: 91px;
                right: 0px;
            }
    
            &.gallery-image-6 {
                right: 0;
            }
            img {
                max-width: 80%;
            }
        }

    }
}

// Google reviews
.google-reviews-container-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.google-reviews-container {
    margin-top: -5px;
    .inner {
        .content {
            .title {
                margin-top: 0!important;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .google-reviews-container {
        margin: 0;
    }
}
// for G
.google-reviews-1 {
    color: #4285F4;
}
// for o
.google-reviews-2 {
    color: #EA4335;
}
// for 2nd o
.google-reviews-3 {
    color: #FBBC05;
}
// for 2nd g
.google-reviews-4 {
    color: #4285F4;
}
// for l
.google-reviews-5 {
    color: #34A853;
}
// for e
.google-reviews-6 {
    color: #EA4335;
}
// for stars
.google-reviews-stars {
    color: orange;
    svg {
        margin-top: -4px;
        &:not(:first-of-type) {
            margin-left: 2px;
        }
    }
}

.google-reviews-review-wrapper {
    border: 10px solid #F86F03;
    border-radius: 5px;
    padding: 10px;
    
    .google-reviews-reviewer, p {
        font-size: 12px !important;
    }
}

.google-review-content-wrapper {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 1rem;
}

.google-review-content-description {
    .title {
        line-height: 1!important;
        font-size: 20px!important;
        margin-bottom: 10px!important;
    }
    p {
        margin-bottom: 0;
    }
}

.banner-right-content-wrapper {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 2rem 0;
}

.home-banner {
    width: fit-content;
    .banner-feature {

        background-color : var(--color-secondary);
        width: fit-content;
        // margin-top: 0;
        .icon {
            margin-top: 0;
            opacity: 1;
            // width: fit-content;
        }
        .feature-content {
            opacity: 1;
            width: fit-content;
        }
    }
}

// Banner right content image component
@mixin on-circle($item-count, $circle-size, $item-size) {  
    position: relative;
    width:  $circle-size;
    height: $circle-size;
    border-radius: 50%;
    padding: 0; 
    list-style: none;
    
    > * {
      display: block;
      position: absolute;
      top:  50%;
      left: 50%;
      margin: -($item-size / 2);
      width:  $item-size;
      height: $item-size;
    
      $angle: (360 / $item-count);
      $rot: 0;
  
        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg) translate(-$circle-size / 2) rotate($rot * -1deg);
                // transform: rotate(($rot * 1deg) + 90deg) translate(0, -125px) rotate(0deg);
                z-index: 1;
                &:hover {
                    z-index: 10;
                }
                // .circle-container-text-1 {
                //     transform: rotate(($rot * 1deg) + 90deg);
                // }
            }
  
            $rot: $rot + $angle;
        }
    }
}

main {
    margin-top: 50px;
}

.circle-container {
    @include on-circle($item-count: 7, $circle-size: 30rem, $item-size: 171px);
    // margin: 10rem auto 0;
    // border: solid 88px black;
    transform: rotate(270deg);
    top: 0;
    left: 50%;

    // li .circle-container-text-1 {
    //     position: fixed;
    //     z-index: 11;
    // }
    
  
    img { 
        display: block; 
        max-width: 100%;
        position: relative;
        //   border-radius: 50%;
        // filter: grayscale(100%);
        // border: solid 5px tomato;
        transition: .15s;
        // margin-left: -10px;
        
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center center;
        mask-position: center center;
        transform: rotate(90deg);
        
        &:hover {
            transform: scale(1.2) rotate(90deg);
            cursor: pointer;
            filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
        }
    }
    li:nth-of-type(1) {
        // transform: rotate(0deg) translate(0, -12.5rem) rotate(0deg);
        // background-image: url(../../images/banner-right/circle_piece_2_of_7.png);
        // background-repeat: no-repeat;
        transform: rotate(-0.4deg) translate(143px) rotate(0.4deg);
    }
    li:nth-of-type(2) {
        transform: rotate(48deg) translate(152.3px) rotate(-48deg);
    }
    li:nth-of-type(3) {
        transform: rotate(105deg) translate(140.7px) rotate(-105deg);
    }
    li:nth-of-type(4) {
        transform: rotate(148deg) translate(141.7px) rotate(-148deg);
    }
    li:nth-of-type(5) {
        transform: rotate(212.4deg) translate(142px) rotate(-212.4deg);
    }
    li:nth-of-type(6) {
        transform: rotate(254.7deg) translate(141.4px) rotate(-254.7deg);
    }
    li:nth-of-type(7) {
        transform: rotate(312deg) translate(152px) rotate(-312deg);
    }
}

.rectangle-container {
    @include on-circle($item-count: 6, $circle-size: 35rem, $item-size: 230px);
    // margin: 10rem auto 0;
    // border: solid 88px black;
    transform: rotate(270deg);
    top: 0;
    left: 50%;

    // li .circle-container-text-1 {
    //     position: fixed;
    //     z-index: 11;
    // }
    
  
    img { 
        display: block; 
        max-width: 100%;
        position: relative;
        //   border-radius: 50%;
        // filter: grayscale(100%);
        // border: solid 5px tomato;
        transition: .15s;
        // margin-left: -10px;
        
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center center;
        mask-position: center center;
        transform: rotate(90deg);
        
        &:hover {
            transform: scale(1.2) rotate(90deg);
            cursor: pointer;
            filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
        }
    }
    li:nth-of-type(1) {
        transform: rotate(0deg) translate(-13.8rem) rotate(0deg);
    }
    li:nth-of-type(2) {
        transform: rotate(50deg) translate(-21.5rem) rotate(-50deg);
    }
    li:nth-of-type(3) {
        transform: rotate(115deg) translate(-18rem) rotate(-115deg);
    }
    li:nth-of-type(4) {
        transform: rotate(180deg) translate(-7.5rem) rotate(-180deg);
    }
    li:nth-of-type(5) {
        transform: rotate(245deg) translate(-18rem) rotate(-245deg);
    }
    li:nth-of-type(6) {
        transform: rotate(310deg) translate(-21.3rem) rotate(-310deg);
    }
    li:nth-of-type(7) {
        transform: rotate(300deg) translate(-4.9rem, 4.2rem) rotate(-300deg);
    }
}