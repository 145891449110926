/*------------------------
    Service Style  
--------------------------*/
.service-wrapper-1 {
    position: relative;
    .shape-image {
        position: absolute;
        z-index: -1;
        &.shape-image-1 {
            top: -28px;
            left: -206px;
        }
        &.shape-image-2 {
            top: -51px;
            right: -257px;
        }
        &.shape-image-3 {
            bottom: 80px;
            right: -37px;
        }
        &.shape-image-4 {
            bottom: -25px;
            left: -201px;
        }
    }
}

.home-one-cat {
    background-image: url(../../images/bg/home-one-service.jpg);
    background-size: cover;
    background-position: center;
}

// .home-one-programs {
//     background-image: url(../../images/bg/home-four-banner-2.jpg);
//     background-size: cover;
//     background-position: center;
// }

.program-service-card {
    background-color: #f7f5ff !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.service-card-1 {
    background: var(--color-white);
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: 0.4s;
    &::after {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: var(--color-primary);
        transition: 0.4s;
        bottom: 0;
    }
    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                }
            }
        }
        .content {
            padding: 30px;

            @media #{$lg-layout} {
                padding: 25px 20px;
            }

            @media #{$md-layout} {
                padding: 25px 20px;
            }

            @media #{$sm-layout} {
                padding: 25px 20px;
            }

            .course-total {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                display: inline-block;
                margin-bottom: 5px;
                color: var(--color-primary);
            }
            .title {
                margin-bottom: 20px;
                
                @media #{$lg-layout} {
                    margin-bottom: 6px;
                }

                @media #{$md-layout} {
                    margin-bottom: 6px;
                }

                @media #{$sm-layout} {
                    margin-bottom: 6px;
                }
            }
            .description {
                margin-bottom: 0;
            }
        }
        .program-info {
            .program-info-content {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                span {
                    margin-top: 5px;
                }
            }
        }
    }
    &:hover {
        transform: translateY(-15px);
        transition-delay: 0.3s;
        &::after {
            width: 100%;
        }
    }
}

.research-projects {
    height: 100%;
    .inner {
        .thumbnail {
            overflow: hidden;
            img {
                transition-duration: 0.3s;
            }
        }
    }
}

.research-projects:hover {
    img {
        transform: scale(1.2);
    }
}

/*----------------------
    Service Style Two  
-----------------------*/
.service-card-2 {
    padding: 40px 30px;
    background: #EAF8F6;
    border-radius: 5px;
    
    @media #{$lg-layout} {
        padding: 40px 20px;
    }
    
    @media #{$md-layout} {
        padding: 40px 20px;
    }
    
    @media #{$sm-layout} {
        padding: 40px 20px;
    }
    .inner {
        text-align: center;
        .icon {
            position: relative;
            display: inline-block;
            a {
                width: 80px;
                height: 80px;
                background: var(--color-white);
                line-height: 80px;
                text-align: center;
                border-radius: 100%;
                margin: 0 auto;
                display: block;
            }
            img {
                
            }
            .shape-list {
                .shape {
                    position: absolute;
                    z-index: 2;
                    &.shape-1 {
                        bottom: 0;
                        right: 0;
                        transition: 0.4s;
                        opacity: 0;
                    }
                    &.shape-2 {
                        left: 0;
                        top: 50%;
                        transition: 0.4s;
                        opacity: 0;
                    }
                    &.shape-3 {
                        top: 0;
                        right: 0;
                        transition: 0.4s;
                        opacity: 0;
                    }
                }
            }
        }
        .content {
            .title {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
                margin-top: 25px;
            }
            .description {
                margin-bottom: 0;
            }
        }
    }
    
    &.card-bg-2 {
        background: #FFF3EE;
    }
    &.card-bg-3 {
        background: #EFF4FC;
    }
    &.card-bg-4 {
        background: #FFF1F1;
    }

    &:hover {
        .inner {
            .icon {
                .shape-list {
                    .shape {
                        &.shape-1 {
                            bottom: -8px;
                            right: 3px;
                            opacity: 1;
                        }
                        &.shape-2 {
                            left: -20px;
                            top: 50%;
                            opacity: 1;
                        }
                        &.shape-3 {
                            top: -5px;
                            right: -1px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.courses-description-content {
    max-width: 900px;
    margin: auto;
    p {
        font-size: 18px;
        margin-bottom: 0;
    }
}

.courses-description-why-join-us-section {
    ol {
        margin: 0 auto;
        li {
            line-height: 1.8;
        }
        // max-width: 700px;
    }
    p {
        max-width: 900px;
        margin: 16px auto;
        font-size: 18px;
    }
}

/* Sponsors */
.sponsors-wrapper {
    .inner {
        text-align: center;

        .icon {
            // background: #FFFFFF;
            // box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
            // border-radius: 10px;
            width: 160px;
            height: 160px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 80px;
            }
        }
    }
}

.sponsors-desktop {
    display: none;
}

.sponsors-tablet {
    display: none;
}

.sponsors-mobile {
    display: block;
}

@media screen and (min-width: 800px) {
    .sponsors-desktop {
        display: none;
    }

    .sponsors-tablet {
        display: block;
    }

    .sponsors-mobile {
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    .sponsors-desktop {
        display: block;
    }

    .sponsors-tablet {
        display: none;
    }

    .sponsors-mobile {
        display: none;
    }
}

.course-description-mobile-content {
    display: flex;
    img {
        border-radius: 12px;
    }
}

.course-description-desktop-content {
    display: none;
}

@media screen and (min-width: 768px) {
    .course-description-mobile-content {
        display: none;
    }
    
    .course-description-desktop-content {
        display: block;
    }
}

/*----------------------
    Service Style Three  
-----------------------*/

.video-section-overlayto-another {
    position: relative;
    margin-bottom: -281px;
    z-index: 1;
}

.service-wrapper-3 {
    position: relative;
    z-index: 1;
    padding-bottom: 281px;
    .about-us-two-service-wrapper {
        background-image: url(../../images/bg/about-us-two-service.jpg);
    }
}


.service-card-3 {
    padding: 30px 30px;
    background: var(--color-white);
    border-radius: 5px;
    transition: 0.4s;
    position: relative;
    z-index: 2;
    .inner {
        text-align: center;
        .icon {
            position: relative;
            display: inline-block;
            margin-bottom: 25px;
            a {
                width: 70px;
                height: 70px;
                background: var(--color-primary);
                text-align: center;
                margin: 0 auto;
                border-radius: 35px 35px 3px 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    font-size: 32px;
                    color: var(--color-white);
                }
            }
        }
        .content {
            .title {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
            }
            .description {
                margin-bottom: 20px;
            }
        }
    }

    &.shape-bg-1 {
        .inner {
            .icon {
                a {
                    background: rgba(113, 82, 233, .15);
                    i {
                        color: var(--color-tertiary-2);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-tertiary-2);
                    }
                }
            }
        }
    }

    &.shape-bg-2 {
        .inner {
            .icon {
                a {
                    background: rgba(255, 164, 27, .15);
                    i {
                        color: var(--color-tertiary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-tertiary);
                    }
                }
            }
        }
    }

    &.shape-bg-3 {
        .inner {
            .icon {
                a {
                    background: rgba(82, 95, 225, .15);
                    i {
                        color: var(--color-primary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-primary);
                    }
                }
            }
        }
    }

    &.shape-bg-4 {
        .inner {
            .icon {
                a {
                    background: rgba(248, 111, 3, .15);
                    i {
                        color: var(--color-secondary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-secondary);
                    }
                }
            }
        }
    }


    &.text-left {
        .inner {
            text-align: left;
        }
    }
    &.bg-grey {
        background: #F5F5F5;
        height: 100%;
        &:hover {
            background: var(--color-white);
            box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07);
        }
    }
    &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07);
        .inner {
            .icon {
                a {
                    i {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}


/*-------------------------------
    Service Style Four  
---------------------------------*/

.service-card-4 {
    height: 100%;
    .inner {
        background: #F5F5F5;
        border-radius: 5px;
        padding: 40px 35px;
        text-align: center;
        transition: 0.4s;
        height: 100%;

        @media #{$lg-layout} {
            padding: 25px 14px;
        }

        @media #{$md-layout} {
            padding: 25px 14px;
        }

        @media #{$sm-layout} {
            padding: 25px 14px;
        }

        .icon {
            position: relative;
            i {
                display: block;
                color: var(--color-primary);
                font-size: 35px;
            }
            .subtitle {
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 0.4s;
            }
        }
        .content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
                margin-top: 25px;
                a {
                    transition: 0.4s;
                    color: var(--color-heading);
                }
            }
            .description {
                margin-bottom: 0;
                transition: 0.4s;
            }
        }
    }
    &:hover {
        .inner {
            background: var(--color-primary);
            .icon {
                i {
                    opacity: 0;
                }
                .subtitle {
                    opacity: 1;
                    color: var(--color-white);
                }
            }
            .content {
                .title {
                    a {
                        color: var(--color-white);
                    }
                }
                .description {
                    color: var(--color-white);
                }
            }
        }
    }
}


/*-------------------------------
    Service Style Five  
---------------------------------*/
.service-card-5 {
    .inner {
        background: #F5F5F5;
        border-radius: 5px;
        padding: 20px;
        transition: 0.4s;
        display: flex;
        align-items: center;
        .icon {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.02);
            border-radius: 2px;
            transition: 0.4s;
            justify-content: center;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 15px;
            }
            i {
                color: var(--color-primary);
                font-size: 30px;
                transition: 0.4s;
            }
        }
        .content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                transition: 0.4s;
                @media #{$lg-layout} {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
            .description {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                transition: 0.4s;
            }
        }
    }
    &:hover {
        .inner {
            background: var(--color-primary);
            .icon {
                box-shadow: none;
                background: rgba(255,255,255,0.15);
                i {
                    color: var(--color-white);
                }
            }
            .content {
                .title {
                    a {
                        color: var(--color-white);
                    }
                }
                .description {
                    color: var(--color-white);
                }
            }
        }
    }
}

/*-------------------------------
    Service Style 8  
---------------------------------*/

.service-activation-item5 {
    .single-slick-card {
        margin-bottom: 20px;
    }
}



.service-card-8 {
    .inner {
        background: #EAF8F6;
        border-radius: 5px;
        text-align: center;
        padding: 30px;
        position: relative;
        transition: 0.4s;
        margin-bottom: 20px;
        .icon {
            position: relative;
            width: 75px;
            height: 75px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border-radius: 100%;
            justify-content: center;
            margin: 0 auto;
        }

        .content {
            margin-top: 20px;
            .title {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 5px;
            }
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
        }

        .hover-action {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.4s;
            opacity: 0;
            .read-more-btn {
                width: 40px;
                height: 40px;
                background: var(--color-primary);
                color: var(--color-white);
                display: inline-block;
                line-height: 43px;
                border-radius: 100%;
                font-size: 20px;
            }
        }
    }

    &.shape-bg-2 {
        .inner {
            background: #FFF3EE;
        }
    }

    &.shape-bg-3 {
        .inner {
            background: #FAEFFA;
        }
    }

    &.shape-bg-4 {
        .inner {
            background: #FFF1F1;
        }
    }
    &.shape-bg-5 {
        .inner {
            background: #EFF4FC;
        }
    }


    &:hover {
        .inner {
            padding-bottom: 50px;
            margin-bottom: -20px;
            .hover-action {
                opacity: 1;
            }
        }
    }
}




/*-------------------------------
    Service Style 7  
---------------------------------*/

.service-card-single {
    position: relative;
    &:first-child {
        &::after {
            background-image: url(../../images/shape/arrow-down.png);
            content: "";
            width: 155px;
            height: 43px;
            right: -19%;
            top: 40px;
            background-size: cover;
            background-position: center center;
            position: absolute;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
    &:last-child {
        &::after {
            background-image: url(../../images/shape/arrow-top.png);
            content: "";
            width: 155px;
            height: 43px;
            left: -19%;
            top: 0;
            background-size: cover;
            background-position: center center;
            position: absolute;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
}
.service-card-7 {
    position: relative;
    .arrow-icon {
        position: absolute;
        left: 100%;
        top: 40px;
        z-index: 1;
    }


    .inner {
       text-align: center;
        .icon {
            position: relative;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            background: var(--color-secondary);
            border-radius: 100%;
            justify-content: center;
            margin: 0 auto;
            color: var(--color-white);
            margin-bottom: 35px;
            i {
                color: var(--color-white);
                font-size: 32px;
                display: flex;
            }
        }

        .content {
            .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 15px;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    &.shape-bg-2 {
        .inner {
            .icon {
                background: var(--color-primary);
            }
        }
    }

    &.shape-bg-3 {
        .inner {
            .icon {
                background: #FFA41B;
            }
        }
    }
}

/* research page */
.research-element {
    min-height: 600px;
}

/* Research Service card */
.research-service-card {
    background-color: #f7f5ff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: 0.4s;
    height: 100%;
    &::after {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: var(--color-primary);
        transition: 0.4s;
        bottom: 0;
    }
    .inner {
        height: 100%;
        .thumbnail {
            a {
                img {
                    width: 100%;
                }
            }
        }
        .content-wrapper {
            .content {
                padding: 30px;
    
                @media #{$lg-layout} {
                    padding: 25px 20px;
                }
    
                @media #{$md-layout} {
                    padding: 25px 20px;
                }
    
                @media #{$sm-layout} {
                    padding: 25px 20px;
                }
    
                .course-total {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    display: inline-block;
                    margin-bottom: 5px;
                    color: var(--color-primary);
                }
                .title {
                    margin-bottom: 20px;
                    
                    @media #{$lg-layout} {
                        margin-bottom: 6px;
                    }
    
                    @media #{$md-layout} {
                        margin-bottom: 6px;
                    }
    
                    @media #{$sm-layout} {
                        margin-bottom: 6px;
                    }
                }
                .description {
                    margin-bottom: 0;
                }
            }
            .program-info {
                .program-info-content {
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
        .research-service-card-btn {
            position: relative;
            bottom: 0;
            left: 0;
            padding: 30px;
        }
    }
    &:hover {
        transform: translateY(-15px);
        transition-delay: 0.3s;
        &::after {
            width: 100%;
        }
    }
}

/* Job profiles */
.job-profile-list-mobile {
    display: block;
    .image-wrapper {
        height: 140px;
        padding-left: 0;
    }
}

.job-profile-list-desktop {
    display: none;
    .job-profile-wrapper {
        div:nth-child(2) {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

.job-profile-wrapper {
    background-color: white;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: left;
    p {
        margin-bottom: 8px;
    }
    .image-wrapper {
        img {
            height: 100%;
            max-width: initial;
        }
    }
}

@media screen and (min-width: 769px) {
    .job-profile-list-mobile {
        display: none;
    }

    .job-profile-list-desktop {
        display: block;
    }
}

/* Past Projects by students */
.student-project-carousel-container {
    height: 525px;
}

.past-project {
    background-color: #fff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .past-project-title {
        position: fixed;
        bottom: 44px;
        left: 12px;
        color: white;
        background-color: rgba(0, 0, 0, 0.02);
    }
}



/* tabs */
$transition: all 0.6s ease;
.industries-list-tab {
    .tabs {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            background-color: #fff;
            box-shadow: rgba(0,0,0,0.8);
            border-radius: 60px 0 0 60px;
            transition: $transition;
            overflow: hidden;
            z-index: 1;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: inline-block;
                padding: {
                    top: 4px;
                    left: 28px;
                    right: 8px;
                    bottom: 4px;
                }
                h3 {
                    margin: 0;
                }
            }
            &::before {
                content:  "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: var(--color-primary);
                z-index: -1;
                border-radius: 60px 0 0 60px;
                transition: $transition;
            }
            h3 {
                transition: $transition;
                font-size: 20px;
            }
            p {
                margin-bottom: 0;
                display: block;
                transition: $transition;
                // color: $body-color;
            }
            &:active {
                h3 {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
                a {
                    right: 20px;
                }
                &::before {
                    width: 100%;
                    border-radius: 60px 0 0 60px;
                }
            }
            &.current {
                i {
                    color: #fff;
                    transform: rotate(360deg);
                }
                h3 {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
                a {
                    right: 20px;
                }
                &::before {
                    width: 100%;
                    border-radius: 60px 0 0 60px;
                }
            }
        }
    }
}
.industries-img {
    text-align: center;
    img {
        width: auto;
    }
}

.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.industries-content {
    margin-top: 24px;
    h4 {
        margin-bottom: 10px;
    }
}

.tab-selections {
    height: 350px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 20px;        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
    }

    scrollbar-width: 8px;
    scrollbar-color: var(--color-primary) #f2f2f2;
}

.tab-selections-content {
    height: 350px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
    }
    scrollbar-width: 8px;
    scrollbar-color: var(--color-primary) transparent;

    .tab_content {
        max-width: 97%;
    }
}

@media screen and (min-width: 769px) {
    .ml-md-20 {
        margin-left: 20px !important;
    }
    .industries-content {
        margin-top: 0;
    }
}

.courses-eligibility-section, .courses-curriculum-section {
    .container {
        max-width: 900px;
        margin: auto;
    }
}

.clean-technology-programs {
    max-width: 1200px;
    margin: auto;
}

.key-features-description, .job-oppotunities-description {
    max-width: 900px;
    margin: auto;
    font-size: 18px;
}

/* Research Project Employers */
.employer-wrapper {
    background-color: transparent;
    padding: 0 8px;
}

.employer-description {
    max-width: 900px;
    margin: auto;
}

.employer-inner {
    background-color: #e5f8ff;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    height: 100%;
    padding: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        margin-bottom: 8px;
    }
    .image-wrapper {
        img {
            // height: 100%;
            max-width: initial;
        }
    }
    &:hover {
        border-radius: 8px;
        .feature-description {
            color: var(--color-black);
        }
    }
}

/* Data Talent */
.data-talent-description {
    max-width: 900px;
    margin: auto;
    p {
        font-size: 18px;
    }
}

.data-talent-difference {
    padding: 2rem 1rem 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px 8px #d3d6fa;
}

.data-talent-eligibility-section {
    .about-feature-list {
        max-width: 900px;
        margin: auto;
    }
}

.data-talent-riipen {
    max-width: 750px;
    margin: auto;
    background-color: rgb(249, 239, 221);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 8px 8px #d3d6fa;
    .data-talent-image-wrapper {
        max-width: 500px;
        margin: auto;
    }
    p {
        margin-bottom: 0;
    }

    .data-talent-cta {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}

.skillsboost-description {
    max-width: 900px;
    margin: auto;
    padding: 0 2rem;
}

.skillsboost-cta-note {
    font-size: 18px;
}

.skillsboost-who-should-take-this {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    li {
        font-size: 18px;
    }
}

// Jobs page
.jobs-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        border: none;

        .accordion__item {
            margin-bottom: 15px;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion__button {
            border-radius: 5px;
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: #ffffff;
            padding: 20px 25px;
            color: var(--color-heading);
            text-decoration: none;
            position: relative;
            display: block;
            font-size: 18px;
            font-weight: 600;

            &:focus {
                outline: 0;
            }
            
            i {
                position: absolute;
                right: 25px;
                top: 18px;
                font-size: 25px;
                transition: 0.5s;
            }
        }
        .accordion-title.active {
            i {
                transform: rotate(180deg);
            }
        }
        .accordion__panel {
            background-color: var(--color-white);
            position: relative;
            padding-bottom: 20px;
            padding-right: 60px;
            padding-left: 25px;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            p {
                line-height: 1.8;
            }
        }
        .accordion-content.show {
            display: block;
        }
    }
}

.skillsboost_banner_right_illustration {
    width: 80%;
    margin: auto;
}

.datatalent_banner_right_illustration {
    width: 90%;
    margin: auto;
}

// Applied AI solutions page
#FourSight, #PostAI, #RealEstateOutlier, #CourseVibe, #SDGNavigator, #TestGenius, #HealthGuidePlus {
    background-image: url(../../../assets/images/bg/pricing-plan.jpg);
    background-repeat: no-repeat;
    background-attachment: cover;
}

.our-applied-ai-solutions-container {
    max-width: 1440px!important;
}


.about-m2m {
    font-size: 18px;
    max-width: 860px;
    margin: auto;
}